import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgMinus32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M18.469 18.5h6.75q.5 0 .875-.219t.625-.594.375-.844.125-.906q-.063-.563-.156-1t-.344-.75-.625-.5-.875-.125l-18.375-.063q-.5 0-.875.219t-.688.594q-.25.313-.375.75t-.125 1q0 .5.125.938t.375.813q.313.313.688.5t.875.188z" /></SvgIcon>;
};
const Memo = memo(SvgMinus32);
export default Memo;