import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSearch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M22.358 13.479a8.323 8.323 0 0 1-.692 3.37 8.924 8.924 0 0 1-4.671 4.626c-2.115.91-4.699.91-6.796 0a8.969 8.969 0 0 1-4.657-4.626c-.922-2.107-.929-4.618-.001-6.724a8.908 8.908 0 0 1 1.883-2.747A9.012 9.012 0 0 1 10.2 5.513c2.129-.922 4.666-.914 6.796 0a8.947 8.947 0 0 1 2.79 1.865 8.892 8.892 0 0 1 1.881 2.747 8.232 8.232 0 0 1 .691 3.354m6.917 14.01-7.259-7.104a11.047 11.047 0 0 0 1.798-3.03c1.018-2.558.965-5.556-.134-8.08a10.708 10.708 0 0 0-2.359-3.423 10.819 10.819 0 0 0-3.474-2.339c-2.653-1.128-5.863-1.131-8.498.001a11.175 11.175 0 0 0-3.486 2.335 10.942 10.942 0 0 0-2.332 3.425c-1.151 2.642-1.151 5.795 0 8.437a11.312 11.312 0 0 0 2.334 3.456c.998.968 2.17 1.744 3.484 2.304 2.529 1.085 5.528 1.142 8.096.15a11.796 11.796 0 0 0 3.031-1.72l7.267 7.114c.108.107.23.187.364.24.405.162.891.065 1.199-.24.214-.214.321-.484.308-.776a1.101 1.101 0 0 0-.338-.751z" /></SvgIcon>;
};
const Memo = memo(SvgSearch32);
export default Memo;