import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16.016 12.031q-.406 0-.703.281t-.297.688v8q0 .406.297.703t.703.297.703-.297.297-.703v-8q0-.406-.297-.688t-.703-.281M17.672 9q0 .688-.484 1.188t-1.172.5-1.172-.5T14.36 9t.484-1.172 1.172-.484 1.172.484T17.672 9m11.312 1.594q-.625-2.156-1.891-3.953t-2.984-3.078q-1.688-1.313-3.766-2.031T16.015.813q-2.781 0-5.234 1.063T6.484 4.782Q4.64 6.595 3.578 9.048t-1.063 5.234q0 2.406.797 4.578t2.203 3.922q1.406 1.719 3.344 2.938t4.25 1.719v2.75q0 .219.094.422t.281.359q.125.094.281.156t.344.063h.078q.047 0 .109-.031.063 0 2.438-.719t5.219-2.594q2.844-1.906 5.188-5.172t2.344-8.391q0-1.188-.125-2.109t-.375-1.578zM15.078 28.906v-2.281q0-.375-.234-.641t-.578-.328q-2.125-.344-3.922-1.344t-3.109-2.5q-1.281-1.531-2-3.453t-.719-4.047q0-2.406.906-4.5t2.469-3.656 3.656-2.469 4.469-.906q1.906 0 3.688.625t3.219 1.75q1.469 1.094 2.563 2.641t1.625 3.453q.188.531.297 1.297t.109 1.734q0 3.781-1.469 6.391T22.548 25q-2 1.719-4.109 2.641t-3.359 1.266z" /></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;