import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgBurgerText32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M1 32h30a1 1 0 0 0 0-2H1a1 1 0 0 0 0 2m0-8h30a1 1 0 0 0 0-2H1a1 1 0 0 0 0 2m0-8h30a1 1 0 0 0 0-2H1a1 1 0 0 0 0 2M0 9.944V1.831h1.055l2.909 4.196 2.897-4.196h1.055v8.113h-.927V3.291L3.952 7.556.927 3.279v6.664zm9.863 0V1.831h5.563V2.7h-4.59v2.7h4.138v.823h-4.138v2.851h4.659v.869H9.862zm7.36 0V1.831h.985l4.532 6.606V1.831h.939v8.113h-1.008l-4.508-6.595v6.595h-.939zm10.454-8.889a.486.486 0 0 1-.359-.157c-.046-.05-.083-.106-.11-.168s-.041-.131-.041-.209A.52.52 0 0 1 27.48.04a.49.49 0 0 1 .197-.041.555.555 0 0 1 .504.313.516.516 0 0 1 .041.209.52.52 0 0 1-.331.493.559.559 0 0 1-.214.041m2.237 0c-.07 0-.137-.014-.203-.041s-.122-.066-.168-.116c-.046-.05-.083-.106-.11-.168s-.041-.131-.041-.209a.52.52 0 0 1 .151-.371.53.53 0 0 1 .371-.151.555.555 0 0 1 .382.151.522.522 0 0 1 .11.58.602.602 0 0 1-.11.168c-.046.05-.102.089-.168.116s-.137.041-.214.041m-1.124 9.028c-.456 0-.879-.066-1.269-.197s-.73-.33-1.02-.597c-.29-.267-.516-.597-.678-.991s-.243-.846-.243-1.356V1.831h.974v5.111c0 .363.056.69.168.979s.27.531.475.724c.205.193.444.34.719.44s.566.151.875.151c.309 0 .601-.05.875-.151s.514-.247.719-.44c.205-.193.363-.435.475-.724s.168-.616.168-.979V1.831h.974v5.111c0 .51-.081.962-.243 1.356s-.388.724-.678.991c-.29.267-.63.466-1.02.597s-.813.197-1.269.197z" /></SvgIcon>;
};
const Memo = memo(SvgBurgerText32);
export default Memo;