import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAdoptarrow32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M28.125 26.219 10.656 8.969l8.125-.063q.469 0 .797-.328t.328-.797-.328-.797-.797-.328l-10.563.063q-.063 0-.109.016T8 6.782q-.25-.031-.469.047t-.375.266q-.125.125-.188.25t-.094.281q-.063.094-.078.219t-.016.219l.063 10.594q0 .469.328.797t.797.328q.469-.031.797-.359t.328-.797l-.063-8.063 17.5 17.281q.156.156.375.234t.406.078q.219 0 .438-.078t.375-.266q.344-.313.344-.781t-.344-.813z" /></SvgIcon>;
};
const Memo = memo(SvgAdoptarrow32);
export default Memo;