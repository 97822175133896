import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAppIcon = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" sx={{
    ...sx
  }} {...other}><defs><clipPath id="app-icon_svg__a"><path d="M88 368h847v285.82H88z" style={{
          fill: "none"
        }} /></clipPath></defs><path d="M0 0h1024v1024H0z" style={{
      fill: "#d4021d"
    }} /><g style={{
      fill: "none",
      clipPath: "url(#app-icon_svg__a)"
    }}><path d="m854.95 492.47-.77 9.22c-5.38 66.07-26.89 86.44-53.4 86.44-23.82 0-40.73-16.13-37.27-59.16l.77-9.61c5.38-65.69 26.89-86.44 53.39-86.44 23.44 0 40.73 16.52 37.27 59.55Zm-595.46 0-.77 9.22c-5.37 66.07-26.89 86.44-53.4 86.44-23.82 0-40.72-16.13-37.26-59.16l.76-9.61c5.38-65.69 26.9-86.44 53.41-86.44 23.43 0 40.72 16.52 37.26 59.55M825.36 368c-42.2 0-76.53 13.01-100.79 39.94 3.45-9.35 5.28-20.75 5.51-34.18h-147.9c-32.27 0-50.71 14.21-63.39 50.71l6.53-50.71H375.88c-27.36 0-46.14 13.08-52.93 38.7C304.43 382.78 271.52 368 229.9 368c-79.13 0-130.61 45.72-139.83 143.29l-1.15 12.29c-8.07 87.21 37.65 130.23 108.72 130.23 79.14 0 130.62-46.1 139.84-143.68l1.15-12.29c2.08-22.38.56-41.82-4.02-58.39h67.02l-19.2 164.42c-3.85 32.65 13.83 47.63 43.4 47.63 18.06 0 24.2-1.15 31.12-2.3l23.82-209.75h77.99l-19.21 164.42c-3.84 32.65 13.83 47.63 43.41 47.63 18.05 0 24.2-1.15 31.12-2.3l23.81-209.75h38.42c13.29 0 24.03-3.09 32.31-9.2-12.1 21.31-19.99 48.23-23.09 81.03l-1.15 12.29c-8.07 87.21 37.64 130.23 108.71 130.23 79.14 0 130.62-46.1 139.84-143.68l1.15-12.29c8.07-86.83-37.64-129.85-108.72-129.85Z" style={{
        fill: "#fff"
      }} /></g></SvgIcon>;
};
const Memo = memo(SvgAppIcon);
export default Memo;