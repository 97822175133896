import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M28.685 13.035c-.089.4-.224.781-.4 1.133a6.68 6.68 0 0 1-.677 1.088 4.658 4.658 0 0 1-.82.837l-10.776 9.045L5.2 16.062a4.516 4.516 0 0 1-.784-.807 6.749 6.749 0 0 1-.677-1.088 5.023 5.023 0 0 1-.4-1.133 6.281 6.281 0 0 1-.139-1.348c0-.779.143-1.496.436-2.191a5.064 5.064 0 0 1 1.228-1.769 6.063 6.063 0 0 1 1.867-1.275 5.211 5.211 0 0 1 2.156-.452c.581 0 1.131.088 1.68.272a5.638 5.638 0 0 1 1.524.767 5.592 5.592 0 0 1 2.072 2.534 1.999 1.999 0 0 0 3.698 0 5.607 5.607 0 0 1 2.073-2.534 6.796 6.796 0 0 1 1.569-.772 5.26 5.26 0 0 1 3.805.179 6.33 6.33 0 0 1 1.895 1.324 4.95 4.95 0 0 1 1.184 1.727c.293.695.436 1.412.436 2.191 0 .477-.047.931-.139 1.348zm1.546-4.316a7.014 7.014 0 0 0-1.656-2.407 8.258 8.258 0 0 0-2.437-1.688c-.917-.416-1.917-.624-3-.624-.792 0-1.552.124-2.281.375a8.73 8.73 0 0 0-2.032 1 7.592 7.592 0 0 0-2.812 3.437 7.575 7.575 0 0 0-2.812-3.437 7.476 7.476 0 0 0-2-1A7.244 7.244 0 0 0 8.888 4a7.236 7.236 0 0 0-2.968.624 8.14 8.14 0 0 0-2.469 1.688c-.708.667-1.26 1.469-1.656 2.407s-.593 1.927-.593 2.968c0 .625.063 1.219.187 1.781.125.563.313 1.095.563 1.595.251.5.541.968.875 1.405s.709.823 1.125 1.156l12.063 10.125 12.063-10.125c.416-.333.792-.719 1.125-1.156s.624-.905.875-1.405c.249-.5.437-1.032.563-1.595s.187-1.156.187-1.781a7.548 7.548 0 0 0-.593-2.968z" /></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;