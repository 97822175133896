import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCheckmark32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M11.125 28.063q-.188 0-.375-.063t-.375-.125-.344-.156-.281-.281L.687 18.375q-.313-.25-.406-.563t-.031-.625q.063-.375.25-.688t.438-.563q.313-.313.625-.5t.625-.25q.375-.063.688.031t.563.406l7.688 7.688L28.565 5.123q.25-.25.563-.344t.625-.031q.375 0 .688.188t.563.5q.313.313.5.625t.25.625-.031.625-.406.625l-18.813 19.5q-.125.188-.281.281t-.344.156-.375.125-.375.063z" /></SvgIcon>;
};
const Memo = memo(SvgCheckmark32);
export default Memo;