import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgPlus32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M25.219 13.563h-6.75V6.875q0-.5-.188-.906t-.563-.656q-.313-.25-.781-.375t-.969-.125q-.563 0-1 .125t-.813.375q-.313.25-.5.656t-.188.906V13.5H6.842q-.5 0-.875.219t-.688.594q-.25.313-.375.75t-.125 1q0 .5.125.938t.375.813q.313.313.688.5t.875.188h6.625v6.688q0 .5.219.875t.594.625.844.375.969.125.938-.125.75-.313q.313-.25.5-.625t.188-.938v-6.688h6.75q.5 0 .875-.219t.625-.594.375-.844.125-.906q-.063-.563-.156-1t-.344-.75-.625-.469-.875-.156" /></SvgIcon>;
};
const Memo = memo(SvgPlus32);
export default Memo;