import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M30.094 25.688q-.281-1-1.297-1.813t-2.422-1.406-3-.953-3.031-.453v-1.688q0-.031-.016-.063t-.016-.063q.5-.719.797-1.5t.422-1.469q.563-.281.891-1.016t.453-1.547l.094-1.156q.094-1.156-.375-1.906.031-.969-.25-2.813T20.75 4.529q-.844-.906-2.031-1.375t-2.688-.469q-1.469 0-2.641.453t-2.016 1.328Q9.999 5.872 9.733 7.7t-.266 2.859q-.438.625-.438 1.625t.063 1.531q.156.813.5 1.563t.906 1.031q.156.906.453 1.672t.703 1.391v1.594q-1.281.094-2.859.484t-3.016 1.016q-1.438.594-2.531 1.406t-1.375 1.813q-.25.969-.172 1.922t.078.984q.063.313.297.516t.547.203h.126q.344-.063.563-.344t.156-.625q0-.031-.063-.766t.125-1.422q.156-.5.969-1.063t1.969-1.063 2.531-.875 2.656-.469V23.793q0 .078.031.141t.875 1.297 3.344 1.234q2.469 0 3.406-1.156t.969-1.25q.031-.063.047-.156t.016-.156v-.969q1.469.094 2.875.438t2.5.844q1.125.469 1.859 1.016t.891 1.078q.188.688.125 1.422t-.063.766q-.063.344.141.625t.547.344h.157q.313 0 .547-.203t.297-.516q0-.031.078-.984t-.203-1.922zM11.25 14.625h-.094q-.094-.156-.188-.453t-.188-.734q-.094-.531-.063-1.031t.094-.719q.156-.094.25-.234t.125-.297q0-.063.016-.156t-.016-.156q0-.656.125-2.328t1.281-2.859q.594-.625 1.453-.938t1.984-.313 1.984.328 1.453.953q1.156 1.25 1.297 2.922t.078 2.297q0 .188.078.391t.234.328q.063.219.094.734t-.063 1.109q-.063.406-.156.688t-.188.438q-.094 0-.172.016t-.141.047q-.25.094-.406.281t-.188.438q0 .031-.688 2.328t-3.313 2.297q-1 0-1.688-.406t-1.125-1.063l-.016-.016-.016-.016q-.438-.656-.672-1.453t-.328-1.578v-.126q-.031-.25-.219-.438t-.438-.25q-.031-.031-.094-.031t-.094 0zm4.656 10.156q-1.344 0-1.891-.516t-.672-.766V21.03q.563.313 1.219.484t1.375.172q.813 0 1.484-.203t1.203-.547v2.594q-.156.25-.781.75t-1.938.5z" /></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;