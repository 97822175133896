import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M27.2 5.3c0-.2-.1-.4-.3-.6-.2-.2-.4-.2-.6-.2H5.1c-.2 0-.4.1-.6.2-.1.2-.2.4-.2.7-.3 7.1-.8 15.9-1 21.2v.3c0 .1.1.2.2.3.1.1.2.1.3.2s.2.1.3.1H24.7c.1 0 .1 0 .2-.1h.2c1.1-.9 2.6-2 3.4-2.7.1-.1.2-.2.2-.3s.1-.3.1-.4c-.6-6.3-1.2-14-1.6-18.7m-21.3.9h16.7c.3 4.9.8 14.6 1 19.5H4.9c.3-4.8.8-14.6 1-19.5M25.3 25c-.2-4.7-.7-14-1-18.7h1.3c.4 4.3 1.1 13 1.5 17.3-.5.3-1.4 1-1.8 1.4M19.4 8.1c-.2 0-.4.1-.6.2s-.2.4-.2.6c0 .9-.1 1.7-.4 2.4-.2.7-.6 1.3-1 1.9-.4.4-.9.7-1.4 1-.5.2-1 .4-1.6.4-1.1 0-2-.5-3-1.4-.9-.9-1.4-2.3-1.4-4.2 0-.2-.1-.4-.3-.6 0-.3-.2-.3-.4-.3s-.4.1-.6.2c-.2.2-.3.4-.3.6 0 1.2.2 2.3.5 3.2.4.9.8 1.7 1.4 2.3.6.6 1.2 1.1 1.9 1.4.7.3 1.5.5 2.2.5.8 0 1.5-.2 2.3-.5.7-.3 1.4-.8 1.9-1.4.6-.7 1.1-1.4 1.4-2.4.3-.9.5-1.9.5-3 0-.2-.1-.4-.2-.6-.2-.3-.4-.3-.7-.3" /></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;