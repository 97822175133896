import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgLogo = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon viewBox="0 0 514 174" sx={{
    ...sx
  }} {...other}><path fill="#F00020" d="M446.345.396c-25.482 0-46.211 7.856-60.857 24.12 2.086-5.646 3.185-12.531 3.327-20.64h-89.309c-19.487 0-30.624 8.582-38.278 30.621l3.946-30.621h-90.242c-16.522 0-27.864 7.899-31.962 23.37C131.787 9.326 111.917.398 86.778.398c-47.784 0-78.871 27.606-84.44 86.529l-.696 7.425c-4.872 52.66 22.737 78.64 65.648 78.64 47.791 0 78.877-27.836 84.444-86.762l.696-7.418c1.256-13.514.339-25.255-2.426-35.262h40.469l-11.597 99.288c-2.322 19.717 8.351 28.762 26.21 28.762 10.903 0 14.615-.694 18.791-1.39l14.384-126.66h47.091l-11.6 99.288c-2.316 19.717 8.353 28.762 26.216 28.762 10.9 0 14.616-.694 18.79-1.39l14.381-126.66h23.2c8.025 0 14.505-1.866 19.511-5.554-7.307 12.87-12.074 29.124-13.945 48.934l-.697 7.424c-4.871 52.662 22.733 78.64 65.649 78.64 47.789 0 78.872-27.836 84.441-86.76l.695-7.419C516.869 26.377 489.265.396 446.345.396M104.642 75.558l-.465 5.566c-3.245 39.898-16.237 52.195-32.244 52.195-14.384 0-24.587-9.742-22.502-35.725l.461-5.805c3.25-39.665 16.243-52.193 32.249-52.193 14.149.004 24.589 9.981 22.501 35.962m359.57 0-.466 5.566c-3.248 39.898-16.237 52.195-32.245 52.195-14.384 0-24.593-9.742-22.503-35.725l.465-5.805c3.249-39.665 16.238-52.193 32.244-52.193 14.152.004 24.591 9.981 22.505 35.962" /></SvgIcon>;
};
const Memo = memo(SvgLogo);
export default Memo;