import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlistCss32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M30.2 8.7c-.4-.9-.9-1.7-1.7-2.4-.7-.7-1.5-1.3-2.4-1.7-.9-.4-1.9-.6-3-.6-.8 0-1.6.1-2.3.4-.7.2-1.4.6-2 1-.6.4-1.2.9-1.7 1.5s-.9 1.2-1.2 1.9c-.3-.7-.7-1.4-1.2-1.9-.5-.6-1-1.1-1.7-1.5-.6-.4-1.3-.8-2-1-.6-.3-1.3-.4-2.1-.4-1 0-2 .2-3 .6-.9.4-1.7 1-2.4 1.7-.8.7-1.3 1.5-1.7 2.4s-.6 1.9-.6 3c0 .6.1 1.2.2 1.8.1.5.3 1.1.6 1.6s.5 1 .9 1.4.7.8 1.1 1.2l12 10 12.1-10.1c.4-.3.8-.7 1.1-1.2s.6-.9.9-1.4c.2-.5.4-1 .6-1.6s.2-1.2.2-1.8c-.1-1-.3-1.9-.7-2.9" className="wishlist-css-32_svg__fill" /><path d="M28.7 13c-.1.4-.2.8-.4 1.1-.2.4-.4.8-.7 1.1-.2.3-.5.6-.8.8L16 25 5.2 16.1c-.3-.2-.6-.5-.8-.8-.3-.3-.5-.7-.7-1.1-.2-.4-.3-.7-.4-1.1-.1-.4-.1-.9-.1-1.3 0-.8.1-1.5.4-2.2.3-.7.7-1.2 1.2-1.8.5-.5 1.1-1 1.9-1.3.7-.4 1.4-.5 2.2-.5.6 0 1.1.1 1.7.3.6.2 1.1.4 1.5.8.5.3.9.7 1.2 1.1.4.4.6.9.9 1.4.3.7 1 1.2 1.8 1.2s1.5-.5 1.8-1.2c.2-.5.5-1 .9-1.4.4-.4.8-.8 1.2-1.1.5-.3 1-.6 1.6-.8 1.3-.4 2.7-.3 3.8.2.7.3 1.3.7 1.9 1.3.5.5.9 1 1.2 1.7.3.7.4 1.4.4 2.2 0 .5 0 .9-.1 1.3m1.5-4.3c-.4-.9-.9-1.7-1.7-2.4-.7-.7-1.5-1.3-2.4-1.7-.9-.4-1.9-.6-3-.6-.8 0-1.6.1-2.3.4-.7.2-1.4.6-2 1-.6.4-1.2.9-1.7 1.5s-.9 1.2-1.2 1.9c-.3-.7-.7-1.4-1.2-1.9-.5-.6-1-1.1-1.7-1.5-.6-.4-1.3-.8-2-1-.6-.3-1.3-.4-2.1-.4-1 0-2 .2-3 .6-.9.4-1.7 1-2.4 1.7-.8.7-1.3 1.5-1.7 2.4s-.6 1.9-.6 3c0 .6.1 1.2.2 1.8.1.5.3 1.1.6 1.6s.5 1 .9 1.4.7.8 1.1 1.2l12 10 12.1-10.1c.4-.3.8-.7 1.1-1.2s.6-.9.9-1.4c.2-.5.4-1 .6-1.6s.2-1.2.2-1.8c-.1-1-.3-1.9-.7-2.9" className="wishlist-css-32_svg__border" /></SvgIcon>;
};
const Memo = memo(SvgWishlistCss32);
export default Memo;